<form [formGroup]="form" class="u-flex-column u-flex-align-items-start u-w100p" (ngSubmit)="onSubmit()">
    <app-select-input
        [options]="homeDescriptionOptions"
        [label]="'HOME_DESCRIPTION.TYPE' | translate"
        [hasMargin]="false"
        [hasMarginDouble]="true"
        formControlName="homeDescription"
        data-testid="home-description"
    ></app-select-input>

    <ng-container *ngIf="showDescriptionSize()">
        <app-select-input
            *ngIf="withHomeDescriptionSizeBuckets; else sizeInput"
            [custom]="false"
            [label]="'HOME_DESCRIPTION.HOME_DESCRIPTION_SIZE' | translate"
            [hasMargin]="false"
            [hasMarginDouble]="true"
            [options]="homeDescriptionSizeOptions"
            formControlName="homeDescriptionSize"
            data-testid="home-description-size"
        ></app-select-input>

        <ng-template #sizeInput>
            <app-number-input
                formControlName="homeDescriptionSizeInput"
                [label]="'HOME_DESCRIPTION.HOME_DESCRIPTION_SIZE_WITH_UNIT' | translate"
                [hasMargin]="false"
                [hasMarginDouble]="true"
                [placeholder]="'HOME_DESCRIPTION.SURFACE.PLACEHOLDER' | translate"
                [step]="homeSizeStep"
                data-testid="home-description-size-input"
            ></app-number-input>
        </ng-template>
    </ng-container>

    <app-number-input
        formControlName="homeDescriptionApartmentLevel"
        *ngIf="showHomeDescriptionApartmentLevel()"
        [label]="'HOME_DESCRIPTION.APARTMENT_LEVEL' | translate"
        [hasMargin]="false"
        [hasMarginDouble]="true"
    ></app-number-input>

    <app-previous-submit (previous)="onPrevious()"></app-previous-submit>
</form>
