<app-loading-container [loading]="uiSandbox.moveLoading$ | async">
    <div class="u-flex-column u-flex-align-items-start">
        <h3>{{ 'REAL_ESTATE_AGENT.EOTS.PAYMENT_DETAILS.TITLE' | translate }}</h3>
        <p class="u-color-muted u-margin-bottom-triple">
            {{
                'STEP_INDICATION'
                    | translate
                    : {
                        start: stepStart,
                        end: stepEnd
                    }
            }}
        </p>

        <form
            [formGroup]="form"
            class="u-flex-column u-flex-align-items-start u-w100p"
            [ngSwitch]="showGeneralPaymentType$ | async"
            (ngSubmit)="onSubmit($event)"
        >
            <app-check-input
                [option]="differentPaymentMethodsOption"
                *ngIf="(moveSandbox.payableShoppingCartWithoutInsurancesLength$ | async) > 1"
                [hasMargin]="false"
                [hasMarginDouble]="true"
                [custom]="false"
                width="auto"
                [formControlName]="paymentFields.differentPaymentMethods"
            ></app-check-input>

            <div *ngSwitchCase="true">
                <app-select-input
                    [label]="'PAYMENT_TYPE.LABEL' | translate"
                    [custom]="false"
                    [hasMargin]="false"
                    [hasMarginDouble]="true"
                    [options]="generalPaymentTypeOptions"
                    [formControlName]="paymentFields.generalPaymentType"
                    data-testid="payment-type-input"
                ></app-select-input>
            </div>

            <div *ngSwitchCase="false">
                <app-select-input
                    [label]="'PAYMENT_TYPE.ENERGY.LABEL' | translate"
                    [custom]="false"
                    [hasMargin]="false"
                    [hasMarginDouble]="true"
                    *ngIf="moveSandbox.payableShoppingCartHasEnergy$ | async"
                    [options]="energyPaymentTypeOptions"
                    [formControlName]="paymentFields.energyPaymentType"
                    data-testid="payment-type-energy-input"
                ></app-select-input>

                <app-select-input
                    [label]="'PAYMENT_TYPE.TELECOM.LABEL' | translate"
                    [custom]="false"
                    [hasMargin]="false"
                    [hasMarginDouble]="true"
                    *ngIf="moveSandbox.payableShoppingCartHasTelecom$ | async"
                    [options]="telecomPaymentTypeOptions"
                    [formControlName]="paymentFields.telecomPaymentType"
                    data-testid="payment-type-telecom-input"
                ></app-select-input>
            </div>

            <app-account-number-input
                [label]="'ACCOUNT_NUMBER' | translate"
                [required]="true"
                [hasMargin]="false"
                [hasMarginDouble]="true"
                *ngIf="showAccountNumberFormControl()"
                [id]="'account-number'"
                [formControlName]="paymentFields.accountNumber"
            ></app-account-number-input>

            <app-select-input
                [label]="'PAYMENT_TYPE.INSURANCES.LABEL' | translate"
                [custom]="false"
                [hasMargin]="false"
                [hasMarginDouble]="true"
                [options]="insurancesPaymentTypeOptions"
                *ngIf="moveSandbox.payableShoppingCartHasInsurances$ | async"
                [formControlName]="paymentFields.insurancesPaymentType"
            ></app-select-input>

            <p
                class="u-color-muted u-margin-bottom-double"
                *ngIf="(moveSandbox.payableShoppingCartHasEnergy$ | async) && energyDomiciliationPaymentMandatory"
            >
                {{ 'REAL_ESTATE_AGENT.EOTS.PAYMENT_DETAILS.ENERGY_BRUSSELS_PAYMENT' | translate }}
            </p>

            <p class="u-color-muted u-margin-bottom-double"
               *ngIf="(moveSandbox.payableShoppingCartLength$ | async) === 0">
                {{ 'PAYMENT_TYPE.TRANSFER' | translate }}
            </p>

            <app-suppliers-terms-and-conditions
                [hasMarginDouble]="true"
                [hasMargin]="false"
                *ngIf="showSuppliersTermsAndConditions"
                [formControlName]="paymentFields.suppliersTermsAndConditions"
            ></app-suppliers-terms-and-conditions>

            <app-smooved-terms-and-conditions
                *ngIf="showSmoovedTermsAndConditions"
                [hasMarginDouble]="true"
                [hasMargin]="false"
                [formControlName]="paymentFields.termsAndConditions"
            ></app-smooved-terms-and-conditions>

            <ng-container *ngIf="showSepa$ | async">
                <app-check-input
                    [custom]="false"
                    [option]="sepaOption"
                    [hasMargin]="false"
                    [hasMarginDouble]="true"
                    [formControlName]="paymentFields.sepa"
                >
                    <button
                        app-button
                        appearance="link"
                        context="muted"
                        class="u-margin-left-half u-padding-top-none u-padding-bottom-none"
                        (click)="showSepaInfo()"
                    >
                        {{ 'COMMON.MORE_INFO' | translate }}
                    </button>
                </app-check-input>
            </ng-container>

            <app-check-input
                [custom]="false"
                *ngIf="showHopalaOptInPartner$ | async"
                [option]="hopalaOptInPartnerOption"
                [hasMargin]="false"
                [hasMarginDouble]="true"
                formControlName="hopalaOptInPartner"
            ></app-check-input>

            <app-previous-submit
                submitTranslationLabel="SUBMIT"
                (previous)="goToPrevious()"
                [submitDisabled]="uiSandbox.moveLoading$ | async"
            ></app-previous-submit>
        </form>
    </div>
</app-loading-container>
